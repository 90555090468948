import { Link } from "gatsby"
import React from "react"


const Content2Cols = ({ content }) => (
    <section className="primary ">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div 
                        className="content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
            </div>
        </div>
    </section>
)


export default Content2Cols
