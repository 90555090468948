import React from "react"
import PropTypes from "prop-types"

import Logo from "../assets/svg/asana_travel_logo.svg"
import bg_default from "../assets/img/bg/trees.jpg"

import Header from "../components/header";
import PageHeder from "../components/header-single-cols-2"
import Footer from "../components/footer";
import Section from "../components/section";

import "./layout-2cols.scss";

const Layout = ({ children, page, source, type }) => {
    
    let screenType = '2cols'; 
    let headerStyle = 'boxed';
    let featuredImage =  page && page.featuredImage ? page.featuredImage.node.sourceUrl : bg_default;
    let featuredImageSet = page && page.featuredImage && page.featuredImage.node.srcSet ? page.featuredImage.node.srcSet : null;


    return (
      <>
        <div className={`layout ` + type + ` cols-2 screen-2 ` + screenType}>
         
          <div className="container-fluid p-0">
            <div className="row no-gutters">
                <div className="col-12 col-md-12 col-lg-6">
                  <div id="sider-left" className="sider-left">
                    <Header Logo={Logo} headerStyle={headerStyle} />
                    <div className="sections-wrap">
                      <PageHeder page={page}/>
                      <Section>
                        {children}
                      </Section>                      
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <aside className="screen-2" >
                    <img className="imgbg" src={featuredImage} srcSet={featuredImageSet}/>  
                  </aside>                  
                </div>
            </div>
            
          </div>        
        </div>
        <Footer/>  
      </>
    )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
