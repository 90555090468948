import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import Layout from '../layouts/layout-2cols'
import Wrapper from '../templates/wrapper'
import Content2Cols from '../components/content/content-2cols'



const Page = ({ data }) => {
  const { wpPage: page } = data

  let siteTitle = page && page.seo.title ? page.seo.title : `${page.title} `;

  return (
    <Layout type="boxed" page={page} source="page">
        <Helmet encodeSpecialCharacters={false}>
            <meta charSet="utf-8" />
            <title>{siteTitle}</title>
        </Helmet>
        <Wrapper>
            <Content2Cols content={page.content}/>
        </Wrapper>
    </Layout>
  )
}


Page.propTypes = {
  data: PropTypes.object.isRequired,
}


export default Page


export const pageQuery = graphql`
  query Cols2PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      excerpt
      meta {
        metaTemplate
      }
      featuredImage {
          node {
              sourceUrl
              title
              altText
              srcSet
              mediaDetails {
                sizes {
                  width
                  height
                  sourceUrl
                }
              }
          }
      }
      seo {
        title
      }
    }
  }
`
