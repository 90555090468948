import { Link } from "gatsby"
import React from "react"

const PageHeader = ({ page }) => {

    return (
        <section className={`page-header `} >
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="title">{page.title}</h1>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PageHeader
